import './vendor/01_autotrack.js'
import './vendor/02_modernizr.min.js'
import Headroom from './vendor/03_headroom.min.js'
import './vendor/03b_imagesloaded.pkgd.min.js'
import './vendor/04_lazysizes.min.js'
import './vendor/05_jquery.waypoints.js'
import Instafeed from './vendor/06_instafeed.min.js'
import './vendor/07_unslider.min.js'
import './vendor/08_cssua.min.js'
// import './vendor/09_isotope.pkgd.min.js'

const Revel = {

  init() {

    this._cache();
    this._events();
    this._initHeadroom();
    // this._initMasonry();
    // this._initInstagram();
    this._initWaypoints();
    //this._initQuoteSlider();
    this._heroAnimation();

    if ($('body').hasClass('page-template-page-work') || $('body').hasClass('single-project') || $('body').hasClass('tax-project_category')) {
      this._detectFilters();
    }

    if ($('.js-map').length) {
      this._createMap();
    }

  }, // init()

  _cache() {

    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mainNav = $('.js-nav');
    this.$hamburger = $('.js-hamburger');
    this.$masonryGrid = $('.grid--static');
    this.$projectFilters = $('.js-project-filters a');
    this.$animateIn = $('.animate-in');
    this.$fadeIn = $('.fade-in');
    this.$gridItem = $('.grid-item');
    this.$postItem = $('.post-item--blog');
    this.$quotes = $('.js-quotes');
    this.$workIntro = $('.js-work-intro');
    this.$heroVideoLarge = document.getElementById('hero__video-large');
    this.$heroControls = $('.hero__controls');
    this.$heroVideo = $('.hero--video');

  }, // _cache()

  _events() {

    this.$html.on('click', '.js-hamburger', this._showMobileNav.bind(this));
    this.$html.on('click', '.js-scroll-to a', this._scrollToSection.bind(this));
    this.$html.on('click', '.js-scroll-top', this._scrollToTop.bind(this));
    this.$html.on('click', '.js-project-filters a', this._filterProjects.bind(this));
    this.$html.on('click', '.hero--video .hero__content', this._playHeroVideo.bind(this));
    this.$html.on('click', '.video--ctrl-mute, .video--ctrl-unmute', this._muteHeroVideo.bind(this));
    this.$html.on('click', '.video--ctrl-pause, .video--ctrl-play', this._pauseHeroVideo.bind(this));
    this.$html.on('click', '.video--ctrl-fullscr', this._fullscreenHeroVideo.bind(this));
    this.$html.on('click', '.video--ctrl-fullscr-exit', this._exitFullscreenHeroVideo.bind(this));
    this.$html.on('click', '.contact__locations__box .internal__editor a', this._showContactMapAddress.bind(this))
    if (this.$heroVideoLarge) {
      this.$heroVideoLarge.addEventListener('ended', this._resetHeroVideo);
    }
  }, // _events()

  _heroAnimation() {
    this.$win.on('load', function() {
      setTimeout(function() {
        $('.heading--splash').fadeOut(function() {
          $('.hero__intro').fadeIn();
        });
      }, 2500);
    })
  },

  _initQuoteSlider() {
    this.$quotes.unslider({nav: false, arrows: false, animation: 'fade', autoplay: true, delay: 25000});
  },

  _createMap() {

    google.maps.event.addDomListener(window, 'load', this._createMap);
    // Basic options for a simple Google Map
    // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    var mapOptions = {
      // How zoomed in you want the map to start at (always required)
      zoom: 15,
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: false,
      center: new google.maps.LatLng(lat1, lng1),

      // The latitude and longitude to center the map (always required)

      // How you would like to style the map.
      // This is where you would paste any style found on Snazzy Maps.
      styles: [
        {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [
            {
              "saturation": "-100"
            }
          ]
        }, {
          "featureType": "administrative.province",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }, {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
            {
              "saturation": -100
            }, {
              "lightness": 65
            }, {
              "visibility": "on"
            }
          ]
        }, {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
            {
              "saturation": -100
            }, {
              "lightness": "50"
            }, {
              "visibility": "simplified"
            }
          ]
        }, {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "saturation": "-100"
            }
          ]
        }, {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        }, {
          "featureType": "road.arterial",
          "elementType": "all",
          "stylers": [
            {
              "lightness": "30"
            }
          ]
        }, {
          "featureType": "road.local",
          "elementType": "all",
          "stylers": [
            {
              "lightness": "40"
            }
          ]
        }, {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
            {
              "saturation": -100
            }, {
              "visibility": "simplified"
            }
          ]
        }, {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "hue": "#ffff00"
            }, {
              "lightness": -25
            }, {
              "saturation": -97
            }
          ]
        }, {
          "featureType": "water",
          "elementType": "labels",
          "stylers": [
            {
              "lightness": -25
            }, {
              "saturation": -100
            }
          ]
        }
      ]

    };

    var pin = {
      url: '/wp-content/themes/_revel/library/img/map-pin.png',
      size: new google.maps.Size(60, 76),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(15, 38),
      scaledSize: new google.maps.Size(60, 76)
    };

    // Get the HTML DOM element that will contain your map
    // We are using a div with id='map' seen below in the <body>
    var map = document.querySelector('.js-map');

    var map = new google.maps.Map(map, mapOptions);

    var marker = new google.maps.Marker({
      //position: new google.maps.LatLng(30.2850233, -97.7136788),
      position: new google.maps.LatLng(lat1, lng1),
      map: map,
      title: 'Revel',
      icon: pin
    });
  },

  _detectFilters() {

    var category = this.getUrlVars()['category'];

    if (category) {
      this.$workIntro.hide();
      var projectFilter = '.project_category-' + category;
      if (category == 'featured') {
        this.$workIntro.show();
        projectFilter = '*';
      } else {
        this.$workIntro.hide();
      }
      var el = $('[data-filter="' + projectFilter + '"]');
      this.$projectFilters.removeClass('is-active');
      el.addClass('is-active');
      if (category == 'featured') {
        this.$workIntro.show();
        projectFilter = '*';
      }

      this.$masonryGrid.isotope({filter: projectFilter});
    }

    $('.nav__item--work').addClass('is-active');
  },

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
      vars[key] = value;
    });
    return vars;
  },

  _filterProjects: function(e) {
    e.preventDefault();
    var category = this.getUrlVars()['category'];
    var el = $(e.currentTarget);
    this.$projectFilters.removeClass('is-active');
    el.addClass('is-active');
    var projectFilter = el.data('filter');
    if (projectFilter == '*') {
      this.$workIntro.show();
    } else {
      this.$workIntro.hide();
    }
    this.$masonryGrid.isotope({filter: projectFilter});
  },

  _playHeroVideo(e) {
    var heroVideo = $('.hero--video')
    this.$heroVideoLarge.play()
    heroVideo.addClass('is--large-video')
    heroVideo.removeClass('is--small-video')
  },

  _muteHeroVideo(e) {
    if (this.$heroVideoLarge.muted) { // unmute + show mute button
      this.$heroVideoLarge.muted = false
      this.$heroControls.removeClass('is--muted').addClass('is--unmuted')
    } else {
      this.$heroVideoLarge.muted = true
      this.$heroControls.removeClass('is--unmuted').addClass('is--muted')
    }
  },

  _pauseHeroVideo(e) {
    if (this.$heroVideoLarge.paused) { // play video + show pause button
      this.$heroVideoLarge.play()
      this.$heroControls.removeClass('is--paused').addClass('is--playing')
    } else {
      this.$heroVideoLarge.pause()
      this.$heroControls.removeClass('is--playing').addClass('is--paused')
    }
  },

  _fullscreenHeroVideo(e) {
    if ($heroVideoLarge.requestFullscreen) {
      $heroVideoLarge.requestFullscreen();
    } else if ($heroVideoLarge.mozRequestFullScreen) {
      $heroVideoLarge.mozRequestFullScreen(); // Firefox
    } else if ($heroVideoLarge.webkitRequestFullscreen) {
      $heroVideoLarge.webkitRequestFullscreen(); // Chrome and Safari
    }
  },

  _exitFullscreenHeroVideo(e) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  },

  _resetHeroVideo(e) {
    var heroVideo = $('.hero--video')
    heroVideo.removeClass('is--large-video')
    heroVideo.addClass('is--small-video')
    this.$heroVideoLarge.pause()
    this.$heroVideoLarge.currentTime(0)
  },

  _initInstagram() {
    var instaContainers = $('.js-instagram');
    var hashtag = 'revelweb';
    var taggedPhotos = [];

    function populateInstagram(data, overlay) {

      var caption = data.caption.text;
      var html = [
        '<a href="' + data.link + '" target="_blank">',
        '<div class="grid-item__solid grid-item__solid--instagram lazyload" data-bg="' + data.images.standard_resolution.url + '">',
        '<span class="grid-item__category u-text-lowercase">Instagram</span>',
        '<div class="grid-item__title">',
        '<div class="grid-item__icon"></div>',
        '<h3 class="heading--lg u-text-lowercase">' + caption.substr(0, 100) + '...</h3>',
        '</div>',
        '<div class="grid-item__overlay grid-item__overlay--' + overlay + '"></div>',
        '</div>',
        '</a>'
      ].join('');

      return html;
    }

    var feed = new Instafeed({
      get: 'user', accessToken: '1059947729.1677ed0.4fb30dbc741545d0b66f4e3374c45493', sortBy: 'most-recent', userId: '1059947729', // https://www.instagram.com/nicholsbooth/
      limit: '20',
      mock: true,
      success: function(instagram) {

        if (hashtag.length) {

          // "search" for tagged photos first
          for (var i = 0; i < instagram.data.length; i++) {
            if (instagram.data[i].tags.indexOf(hashtag) >= 0) {
              taggedPhotos.push(instagram.data[i]);
            }
          }

          if (taggedPhotos.length) {
            // populate the containers
            instaContainers.each(function(index, el) {
              $(el).html(populateInstagram(taggedPhotos[index], $(el).data('overlay')));
            });
          }

        } else {

          // populate the containers
          instaContainers.each(function(index, el) {
            $(el).html(populateInstagram(instagram.data[index], $(el).data('overlay')));
          });
        }

      }
    });
    feed.run();

  }, // _loadInstagram

  _initWaypoints() {
    this.$animateIn.waypoint(function() {
      $(this.element).addClass('is--shown')
    }, {offset: '100%'});

    this.$gridItem.waypoint(function() {
      $(this.element).addClass('is--shown')
    }, {offset: '100%'});

    this.$postItem.waypoint(function() {
      $(this.element).addClass('is--shown')
    }, {offset: '100%'});

    this.$fadeIn.waypoint(function() {
      $(this.element).addClass('is--shown')
    }, {offset: '100%'});
  },

  _initMasonry() {

    var grid = this.$masonryGrid.isotope({
      itemSelector: '.grid-item',
      layoutMode: 'masonry',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-item--sizer'
      }
    });

    this.$masonryGrid.imagesLoaded().progress(function() {
      grid.isotope('layout');
    });

  },

  _scrollToTop(e) {
    this.$body.animate({
      scrollTop: 0
    }, 500);
  },

  _scrollToSection(e) {
    var section = $(e.currentTarget).attr('href');
    var el = $(section);
    if (el.length) {
      this.$body.animate({
        scrollTop: el.offset().top - 100
      }, 500);
    }
  },

  _initPopups() {
    $('.js-popup').magnificPopup({type: 'inline', midClick: true, removalDelay: 100, mainClass: 'popup--animate'});
  },

  _initHeadroom() {
    var theHeader = document.querySelector('.js-header');
    var headroom = new Headroom(theHeader);
    headroom.init();
  },

  _showMobileNav(e) {
    e.preventDefault();
    this.$mainNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    this.$body.toggleClass('has-nav');
  },

  _showContactMapAddress(e){
    e.preventDefault();
    var mapUrl = e.currentTarget.attributes.href.value;
    var coordinates = this._extractCoordinates(mapUrl);
    console.log(coordinates + 'sssss')
    if (coordinates) {
      var latitude = coordinates.lat;
      var longitude = coordinates.lng;
      console.log(latitude + ' - lat ---- long - ' + longitude)

      this._showMap(latitude, longitude);
    } else {
      console.error('Invalid or missing coordinates in the Google Maps URL.');
    }
  },

  _extractCoordinates(mapUrl) {
    // Regular expression to extract latitude and longitude from the Google Maps URL
    const regex = /@([-+]?\d*\.?\d+),([-+]?\d*\.?\d+)/;
    const match = mapUrl.match(regex);
    
    if (match && match.length === 3) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);

      // Check if the extracted values are valid numbers
      if (!isNaN(latitude) && !isNaN(longitude)) {
        // Round the values to a reasonable precision, e.g., 6 decimal places
        const roundedLatitude = parseFloat(latitude.toFixed(12));
        const roundedLongitude = parseFloat(longitude.toFixed(12));

        return { lat: roundedLatitude, lng: roundedLongitude };
      }
    }

    return null;
  },

  _showMap(latitude, longitude) {
    // Assuming that 'js-map' is the correct class for your map container
    var mapContainer = document.querySelector('.js-map');

    if (mapContainer) {
      // Map options
      var mapOptions = {
        zoom: 15,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: false,
        center: new google.maps.LatLng(latitude, longitude),
        styles: [
          {
            "featureType": "administrative",
            "elementType": "all",
            "stylers": [
              {
                "saturation": "-100"
              }
            ]
          }, {
            "featureType": "administrative.province",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          }, {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              }, {
                "lightness": 65
              }, {
                "visibility": "on"
              }
            ]
          }, {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              }, {
                "lightness": "50"
              }, {
                "visibility": "simplified"
              }
            ]
          }, {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
              {
                "saturation": "-100"
              }
            ]
          }, {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          }, {
            "featureType": "road.arterial",
            "elementType": "all",
            "stylers": [
              {
                "lightness": "30"
              }
            ]
          }, {
            "featureType": "road.local",
            "elementType": "all",
            "stylers": [
              {
                "lightness": "40"
              }
            ]
          }, {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              }, {
                "visibility": "simplified"
              }
            ]
          }, {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "hue": "#ffff00"
              }, {
                "lightness": -25
              }, {
                "saturation": -97
              }
            ]
          }, {
            "featureType": "water",
            "elementType": "labels",
            "stylers": [
              {
                "lightness": -25
              }, {
                "saturation": -100
              }
            ]
          }
        ]
      };

      // Create a new map instance
      var map = new google.maps.Map(mapContainer, mapOptions);

      // Marker options
      var pin = {
        url: '/wp-content/themes/_revel/library/img/map-pin.png',
        size: new google.maps.Size(60, 76),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(15, 38),
        scaledSize: new google.maps.Size(60, 76)
      };

      // Add a marker to the map
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        map: map,
        title: 'Revel',
        icon: pin
      });
    } else {
      console.error('Map container not found.');
    }
  }

}; // Revel

Revel.init();
